import React, {useContext, useState} from 'react';
import Providers from '~src/components/Providers';
import {AdminTemplate} from '~src/components';
import Content from '~src/components/organisms/AdminOrganism/Stats/Content';
import {LocaleContext} from '~src/context';
import {CalculationType} from '~src/types';

interface IStatsPageProps {}

const StatsPage: React.FC<IStatsPageProps> = () => {
  const {
    pages: {admin},
  } = useContext(LocaleContext);
  const [tabKey, setTabKey] = useState(admin.stats.pageControls.defaultActiveTab);
  const handleChangeTabs = (tabKey: string) => {
    setTabKey(tabKey);
  };
  return (
    <Providers>
      <AdminTemplate pageTitle={admin.stats.title} tabs={admin.stats.pageControls.tabs} onChangeTabs={handleChangeTabs}>
        <Content calculationType={Number(tabKey.split('.')[0]) as CalculationType} />
      </AdminTemplate>
    </Providers>
  );
};

export default StatsPage;
