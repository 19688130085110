import React, {useContext, useEffect, useState} from 'react';
import {useStoreon} from 'storeon/react';
import moment, {Moment} from 'moment';
import styled, {css} from 'styled-components';
import * as colors from '@ant-design/colors';
import {Key, SorterResult} from 'antd/lib/table/interface';
import {PaginationConfig} from 'antd/lib/pagination';
import {IEvents, IState} from '~src/store';
import {LocaleContext} from '~src/context';
import {CalculationType, SorterValue} from '~src/types';
import {formatDDMMYYYYHHmm, formatYYYYMMDD} from '~src/utils/date';

import {Col, Row, Table} from 'antd';
import {CalendarOutlined} from '@ant-design/icons';
import TableColumnAction from './TableColumnAction';
import TableColumnCreatedAtFilter from './TableColumnCreatedAtFilter';
import {IStatItem} from '~src/store/Stats';
import locales from '~src/locales';

export interface IStatsForTable extends IStatItem {
  displayCalculationType: string;
  reportUrl: string;
}
export const statsAdapter = (stats: IStatItem[]): IStatsForTable[] =>
  stats.map(statItem => ({
    ...statItem,
    reportUrl: `/report?calculation_id=${statItem.calculationId}`,
    createdAt: moment(statItem.createdAt).format(formatDDMMYYYYHHmm),
    displayCalculationType: locales.maps.calculationType[statItem.calculationType],
  }));

interface IContentProps {
  calculationType: CalculationType;
}

const Content: React.FC<IContentProps> = ({calculationType}) => {
  const {
    pages: {
      admin: {
        stats: {tablePageSize, tableRowKey, tableColumnActionButton, tableColumnCreatedAtFilter, columns},
      },
    },
    maps: {sorter},
  } = useContext(LocaleContext);
  const {dispatch, stats} = useStoreon<IState, IEvents>('stats');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDate, selectDate] = useState<Moment | undefined>();
  const [isShowCalendarDropdown, setIsShowCalendarDropdown] = useState(false);
  const [sorterValue, setSorterValue] = useState<SorterValue>('descend');

  const fetchStats = (createdAt?: Moment | undefined) => {
    const params: {createdAt?: string} = {};
    if (createdAt) params.createdAt = createdAt.format(formatYYYYMMDD);
    dispatch('stats/fetch-stats', {
      calculationType,
      page: currentPage,
      count: tablePageSize,
      orderBy: sorter[sorterValue],
      ...params,
    });
  };

  useEffect(() => {
    fetchStats(selectedDate);
  }, [sorterValue, currentPage, calculationType]);

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };
  const handleClickAtApproveCalendarButton = () => {
    fetchStats(selectedDate);
    setIsShowCalendarDropdown(false);
  };
  const handleClickAtResetCalendarButton = () => {
    fetchStats();
    setIsShowCalendarDropdown(false);
    selectDate(undefined);
  };
  const handleChangeAnyTableModule = (
    pagination: PaginationConfig,
    filters: Record<string, Key[] | null>,
    sorter: SorterResult<IStatsForTable>,
  ) => {
    if (sorter.columnKey === 'createdAt') {
      const value = sorter.order || 'ascend';
      setSorterValue(value);
    }
  };
  const handlePanelChange = (value: Moment) => {
    selectDate(value);
  };
  const handleFilterDropdownVisibleChange = (visible: boolean) => {
    if (visible && !selectedDate) selectDate(moment());
    setIsShowCalendarDropdown(visible);
  };
  const customColumns = columns.map(column => {
    if (column.key === 'reportUrl') {
      return {
        ...column,
        // eslint-disable-next-line
        render: (reportUrl: string) => <TableColumnAction href={reportUrl}>{tableColumnActionButton}</TableColumnAction>,
      };
    } else if (column.key === 'createdAt') {
      return {
        ...column,
        filterDropdown: (
          <TableColumnCreatedAtFilter
            approveButton={tableColumnCreatedAtFilter.approveButton}
            resetButton={tableColumnCreatedAtFilter.resetButton}
            calendarValue={selectedDate}
            onClickAtApproveCalendarButton={handleClickAtApproveCalendarButton}
            onClickAtResetCalendarButton={handleClickAtResetCalendarButton}
            onPanelChange={handlePanelChange}
          />
        ),
        onFilterDropdownVisibleChange: handleFilterDropdownVisibleChange,
        filterDropdownVisible: isShowCalendarDropdown,
        filterIcon: <CalendarOutlinedStyled $isAccented={Boolean(selectedDate)} />,
        sorter: () => {},
        sortOrder: sorterValue,
      };
    }
    return column;
  });
  return (
    <Row gutter={[50, 50]}>
      <Col span={24}>
        <Table
          rowKey={tableRowKey}
          dataSource={statsAdapter(stats.values)}
          // TODO: подобрать тип
          columns={customColumns as any}
          loading={stats.isLoading}
          pagination={
            stats.count > tablePageSize
              ? {
                  showSizeChanger: false,
                  defaultPageSize: tablePageSize,
                  total: stats.count,
                  onChange: handleChangePage,
                  defaultCurrent: currentPage,
                }
              : false
          }
          // TODO: подобрать тип
          onChange={handleChangeAnyTableModule as any}
        />
      </Col>
    </Row>
  );
};

const CalendarOutlinedStyled = styled(CalendarOutlined)<{$isAccented: boolean}>`
  ${props =>
    props.$isAccented &&
    css`
      color: ${colors.presetPrimaryColors.blue};
    `}
`;

export default Content;
