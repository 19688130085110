import React, {Fragment} from 'react';

import {Button} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';

interface ITableActionColumnProps {
  href?: string;
}

const TableColumnAction: React.FC<ITableActionColumnProps> = ({href, children}) => {
  return (
    <Fragment>
      <Button href={href} icon={<DownloadOutlined />} target={'__blank'}>
        {children}
      </Button>
    </Fragment>
  );
};

export default TableColumnAction;
