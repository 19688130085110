import React from 'react';
import {Button, Calendar, Space} from 'antd';
import styled from 'styled-components';
import moment from 'moment';

interface ITableColumnCreatedAtFilterProps {
  onClickAtApproveCalendarButton: () => void;
  onClickAtResetCalendarButton: () => void;
  onPanelChange: (value: moment.Moment) => void;
  approveButton: string;
  resetButton: string;
  calendarValue?: moment.Moment;
}

const TableColumnCreatedAtFilter: React.FC<ITableColumnCreatedAtFilterProps> = ({
  onClickAtApproveCalendarButton,
  onClickAtResetCalendarButton,
  onPanelChange,
  approveButton,
  resetButton,
  calendarValue,
}) => {
  const value = calendarValue || moment();
  return (
    <CalendarSpaceStyled size={'middle'} direction={'vertical'}>
      <CalendarStyled fullscreen={false} onChange={onPanelChange} value={value} />
      <Space size={'small'}>
        <Button onClick={onClickAtApproveCalendarButton}>{approveButton}</Button>
        <Button onClick={onClickAtResetCalendarButton}>{resetButton}</Button>
      </Space>
    </CalendarSpaceStyled>
  );
};

const CalendarStyled = styled(Calendar)`
  width: 300px;
`;

const CalendarSpaceStyled = styled(props => <Space {...props} />)`
  padding: 20px;
`;

export default TableColumnCreatedAtFilter;
